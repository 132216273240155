import React from 'react';
import './App.css'; // Import the CSS file for styles

function App() {
  return (
    <div className="background-container">
      {/* This div serves only as a container for the background image */}
      <div className="title">SnapJoy</div>
      <div className="signature">coming soon ...</div>
    </div>
  );
}

export default App;
